<template>
	<div class="main">
		<article v-show="!showSwiper" class="main-article__1" id="article1">
			<video class="videoPlayer" :autoplay="true" :muted="true" preload="auto" playsinline @ended="onVideoEnded">
				<source src="@/assets/video/PC_MV_241108.webm" media="all and (min-width: 1281px)" type="video/webm" />
				<source src="@/assets/video/MO_MV_241122.webm" media="all and (max-width: 1280px)" type="video/webm" />
			</video>
		</article>
		<article v-show="showSwiper" class="main-article__1" id="article1">
			<div class="text-area">
				<div class="image">
					<img src="@/assets/images/main/main-article-1-logo-active.svg" alt="로고" />
				</div>
				<h1 class="heading">
					Everything Starts<br class="only-mobile" />
					with Identity
				</h1>
				<div>
					<p class="body1">
						아이덴티티를 활용하면 최고의 사용자 경험을 구현하고,<br class="only-mobile" />
						고객 가입을 늘리며,<br class="only-pc" />
						직원 생산성을 개선하고,<br class="only-mobile" />
						앱 출시를 앞당길 수 있습니다.
					</p>
				</div>
			</div>
			<div class="swiper-container">
				<Swiper
					@swiper="setMainSwiper"
					:pagination="paginationOptions"
					:navigation="navigationOptions"
					:modules="modules"
					class="main__swiper"
					:autoplay="{
						delay: 3000,
						disableOnInteraction: true
					}"
				>
					<!-- <template v-for="banner in bannerData?.data?.banners" :key="banner.bannerId">
						<swiper-slide><img :src="banner.mediaUrl" alt="" /></swiper-slide>
					</template> -->
					<swiper-slide>
						<img src="@/assets/images/main/main-article-1-1.webp" alt="" />
					</swiper-slide>
					<swiper-slide><img src="@/assets/images/main/main-article-1-2.webp" alt="" /></swiper-slide>
					<swiper-slide><img src="@/assets/images/main/main-article-1-3.webp" alt="" /></swiper-slide>
				</Swiper>
				<div class="swiper-navigation-pagination">
					<div class="swiper-main-button-prev"></div>
					<div class="swiper-main-button-pause" :class="{ play: isPaused }" @click="pause"></div>
					<div class="swiper-main-pagination"></div>
					<div class="swiper-main-button-next"></div>
				</div>
			</div>
		</article>
		<div class="link-nav">
			<a href="javascript:void(0)" class="sub-title3" @click="scrollToSection('article2')" :class="{ active: linkNavActive === 1 }">
				특장점
			</a>
			<a href="javascript:void(0)" class="sub-title3" @click="scrollToSection('article4')" :class="{ active: linkNavActive === 2 }">
				도입 효과
			</a>
			<a href="javascript:void(0)" class="sub-title3" @click="scrollToSection('article5')" :class="{ active: linkNavActive === 3 }">
				추천 기업
			</a>
		</div>
		<article class="main-article__2" id="article2">
			<div class="ak-container">
				<div class="head-area">
					<img src="@/assets/images/main/icon-main-lock.svg" alt="" />
					<h4
						class="title2 animate-duration-400 animate-delay-200 animate-before-fadeinup"
						v-animateonscroll="{ enterClass: 'animate-fadeinup' }"
					>
						AlphaKey로 더 쉽게,<br class="only-mobile" />
						더 안전하게 로그인하세요.
					</h4>
					<p
						class="font-body1 animate-duration-400 animate-delay-400 animate-before-fadeinup"
						v-animateonscroll="{ enterClass: 'animate-fadeinup' }"
					>
						회사에서 자주 쓰는 업무 솔루션/앱을<br class="only-mobile" />
						AlphaKey에서 간편하게 로그인할 수 있습니다. <br />
						양자내성암호를 적용한 2단계 로그인 방식이라 보안에 강합니다. <br />
						전문가가 직접 회사로 방문하거나<br class="only-mobile" />
						PC 지원을 통해 이용 방법을 상세히 안내해 드립니다.
					</p>
					<p class="smy">
						※ 양자내성암호(PQC) : 양자컴퓨터를 통한 고도화된 해킹 시도에도<br class="only-mobile" />
						뚫리지 않는 암호 기술
					</p>
				</div>
			</div>
			<div class="tabview-custom">
				<Swiper
					@swiper="setThumbsSwiper"
					:slidesPerView="3"
					:freeMode="true"
					:watchSlidesProgress="true"
					:modules="modules3"
					class="tabHeaderSwiper"
				>
					<swiper-slide>
						<div class="tabview-custom__header">
							<div class="contents-area">
								<h4 class="sub-title1">2단계 보안 <br class="only-mobile" />로그인</h4>
								<span class="image-area">
									<img src="@/assets/images/main/main-article-2-header-1.svg" alt="" />
									<img class="active" src="@/assets/images/main/main-article-2-header-1-active.svg" alt="" />
								</span>
							</div>
						</div>
					</swiper-slide>
					<div class="dot dot1">
						<div></div>
						<div></div>
						<div></div>
						<div class="only-pc"></div>
						<div class="only-pc"></div>
						<div class="only-pc"></div>
						<div class="only-pc"></div>
						<div class="only-pc"></div>
					</div>
					<swiper-slide
						><div class="tabview-custom__header">
							<div class="contents-area">
								<h4 class="sub-title1">쉬운 연동</h4>
								<span class="image-area">
									<img src="@/assets/images/main/main-article-2-header-2.svg" alt="" />
									<img class="active" src="@/assets/images/main/main-article-2-header-2-active.svg" alt="" />
								</span>
							</div></div
					></swiper-slide>
					<div class="dot dot2">
						<div></div>
						<div></div>
						<div></div>
						<div class="only-pc"></div>
						<div class="only-pc"></div>
						<div class="only-pc"></div>
						<div class="only-pc"></div>
						<div class="only-pc"></div>
					</div>
					<swiper-slide
						><div class="tabview-custom__header">
							<div class="contents-area">
								<h4 class="sub-title1">다양한 앱/솔루션 <br class="only-mobile" />지원</h4>
								<span class="image-area">
									<img src="@/assets/images/main/main-article-2-header-3.svg" alt="" />
									<img class="active" src="@/assets/images/main/main-article-2-header-3-active.svg" alt="" />
								</span>
							</div></div
					></swiper-slide>
				</Swiper>

				<Swiper class="tabSwiper" :direction="'horizontal'" :thumbs="{ swiper: thumbsSwiper }" :modules="modules3">
					<swiper-slide>
						<div class="tab-body">
							<div class="main__container">
								<div class="left-area">
									<h2 class="heading2">2단계 보안 로그인</h2>
									<ul>
										<li>
											<span class="check"><img src="@/assets/images/icon/icon-30-check.svg" alt="" /></span>
											<p class="sub-title2">
												홈페이지에 생체 인식 또는 아이디로 로그인한 후, <br />
												안전하게 한 번 더 로그인해요.
											</p>
										</li>
										<li>
											<span class="check"><img src="@/assets/images/icon/icon-30-check.svg" alt="" /></span>
											<p class="sub-title2">
												간편 인증, 문자메시지, 이메일 등 원하는 방법으로 <br />
												2차 인증할 수 있어요.
											</p>
										</li>
									</ul>
								</div>
								<div class="right-area">
									<img src="@/assets/images/main/main-article-2-1.svg" alt="" class="only-pc" />
									<img src="@/assets/images/main/main-article-2-1-mo.svg" alt="" class="only-mobile" />
								</div>
							</div>
						</div>
					</swiper-slide>
					<swiper-slide>
						<div class="tab-body">
							<div class="main__container">
								<div class="left-area">
									<h2 class="heading2">쉬운 연동</h2>
									<ul>
										<li>
											<span class="check"><img src="@/assets/images/icon/icon-30-check.svg" alt="" /></span>
											<p class="sub-title2">
												추가 개발 없이, 우리 회사 인사 데이터를 <br class="only-mobile" />바로 연동할 수 있어요.
											</p>
										</li>
										<li>
											<span class="check"><img src="@/assets/images/icon/icon-30-check.svg" alt="" /></span>
											<p class="sub-title2">
												별도의 서버를 구축하지 않아도 로그인 인증, <br class="only-mobile" />권한 설정 등을 위한<br
													class="only-pc"
												/>
												프로토콜과 연동할 수 있어요.
											</p>
										</li>
									</ul>
								</div>
								<div class="right-area">
									<img src="@/assets/images/main/main-article-2-2.svg" alt="" class="only-pc" />
									<img src="@/assets/images/main/main-article-2-2-mo.svg" alt="" class="only-mobile" />
								</div>
							</div>
						</div>
					</swiper-slide>
					<swiper-slide>
						<div class="tab-body">
							<div class="main__container">
								<div class="left-area">
									<h2 class="heading2">다양한 앱/솔루션 지원</h2>
									<ul>
										<li>
											<span class="check"><img src="@/assets/images/icon/icon-30-check.svg" alt="" /></span>
											<p class="sub-title2">
												업무에서 자주 쓰는 국내외 솔루션/앱과 연동해 <br />
												개별 로그인 없이 이용할 수 있어요.
											</p>
										</li>
									</ul>
								</div>
								<div class="right-area">
									<img src="@/assets/images/main/main-article-2-3.svg" alt="" class="only-pc" />
									<img src="@/assets/images/main/main-article-2-3-mo.svg" alt="" class="only-mobile" />
								</div>
							</div>
						</div>
					</swiper-slide>
				</Swiper>
			</div>

			<!-- <TabView class="tabview-custom">
        <TabPanel>
          <template #header>
            <div class="tabview-custom__header">
              <div class="contents-area">
                <h4 class="sub-title1">로그인</h4>
                <span class="image-area">
                  <img src="@/assets/images/main/main-article-2-header-1.svg" alt="" />
                  <img class="active" src="@/assets/images/main/main-article-2-header-1-active.svg" alt="" />
                </span>
              </div>
            </div>
          </template>
          <div class="tab-body">
            <div class="main__container">
              <div class="left-area">
                <h2 class="heading2">로그인 Process</h2>
                <ul>
                  <li>
                    <span class="badge">1단계</span>
                    <p>
                      로그인 직원 휴대폰에서 [간편인증], [비밀번호 인증] 중 <br class="only-pc" />하나를 골라
                      AlphaKey 앱에 로그인하세요.
                    </p>
                  </li>
                  <li>
                    <span class="badge">2단계</span>
                    <p>로그인 노트북에서 지문을 인증하세요.</p>
                  </li>
                </ul>
              </div>
              <div class="right-area">
                <img src="@/assets/images/main/main-article-2-1.svg" alt="" />
              </div>
            </div>
          </div>
        </TabPanel>
        <TabPanel>
          <template #header>
            <div class="tabview-custom__header">
              <div class="contents-area">
                <h4 class="sub-title1">AlphaKey</h4>
                <span class="image-area">
                  <img src="@/assets/images/main/main-article-2-header-2.svg" alt="" />
                  <img class="active" src="@/assets/images/main/main-article-2-header-2-active.svg" alt="" />
                </span>
              </div>
            </div>
          </template>
          <div class="tab-body">
            <div class="main__container">
              <div class="left-area">
                <h2 class="heading2">AlphaKey</h2>
                <p>
                  현직자, 퇴사자 등 우리 회사 직원 인사 정보 데이터와 우리 회사 관련 데이터를 AlphaKey에서 사용할 수
                  있어요.
                </p>
                <div class="division"></div>
                <ul>
                  <li>
                    <span class="badge full">데이터 연동 프로토콜 (LDAP)</span>
                    <p class="sub-title2">인사/회사 정보 데이터를 Alphakey에서 사용할 수 있게 연동해요.</p>
                  </li>
                  <li>
                    <span class="badge full">권한 설정 프로토콜 (SCIM)</span>
                    <p class="sub-title2">Alphakey 이용 권한을 설정해요.</p>
                  </li>
                  <li>
                    <span class="badge full">로그인 인증 프로토콜 (SAML,OZuth)</span>
                    <p class="sub-title2">1단계/2단계 로그인을 위한 프로토콜이에요.</p>
                  </li>
                </ul>
              </div>
              <div class="right-area">
                <img src="@/assets/images/main/main-article-2-2.svg" alt="" />
              </div>
            </div>
          </div>
        </TabPanel>
        <TabPanel>
          <template #header>
            <div class="tabview-custom__header">
              <div class="contents-area">
                <h4 class="sub-title1">솔루션/앱</h4>
                <span class="image-area">
                  <img src="@/assets/images/main/main-article-2-header-3.svg" alt="" />
                  <img class="active" src="@/assets/images/main/main-article-2-header-3-active.svg" alt="" />
                </span>
              </div>
            </div>
          </template>
          <div class="tab-body">
            <div class="main__container">
              <div class="left-area">
                <h2 class="heading2">솔루션/앱</h2>
                <h3 class="title2">
                  해외 업무 솔루션/앱은 물론<br />
                  국내 솔루션/앱과 연결해 로그인할 수 있어요.
                </h3>
              </div>
              <div class="right-area">
                <img src="@/assets/images/main/main-article-2-3.svg" alt="" />
              </div>
            </div>
          </div>
        </TabPanel>
      </TabView> -->
		</article>
		<article class="main-article__3" id="article3">
			<div class="main__container">
				<div class="paper">
					<Swiper
						:pagination="{
							el: '.swiper-pagination',
							clickable: true
						}"
						:direction="'horizontal'"
						:mousewheel="{ forceToAxis: true, sensitivity: 1, releaseOnEdges: true }"
						:modules="modules2"
						:autoplay="{
							delay: 3000,
							disableOnInteraction: false
						}"
						:breakpoints="{
							'1281': {
								// loop:true,
								direction: 'vertical',
								slidesPerView: 'auto',
								effect: 'creative',
								creativeEffect: {
									prev: {
										translate: [0, '100%', 0],
										opacity: 0
									},
									next: {
										translate: [0, '-100%', 0],
										opacity: 0
									}
								}
							}
						}"
						class="effect__swiper"
					>
						<swiper-slide>
							<div class="paper__item">
								<div class="image-area">
									<picture>
										<source
											srcset="@/assets/images/main/main-article-3-1.png"
											media="all and (min-width: 1281px)"
											type="image/png"
										/>
										<source
											srcset="@/assets/images/main/main-article-3-1-mo.png"
											media="all and (max-width: 1280px)"
											type="image/png"
										/>

										<img src="@/assets/images/main/main-article-3-1.png" alt="" />
									</picture>
								</div>
							</div>
						</swiper-slide>
						<swiper-slide>
							<div class="paper__item">
								<div class="image-area">
									<picture>
										<source
											srcset="@/assets/images/main/main-article-3-2.png"
											media="all and (min-width: 1281px)"
											type="image/png"
										/>
										<source
											srcset="@/assets/images/main/main-article-3-2-mo.png"
											media="all and (max-width: 1280px)"
											type="image/png"
										/>

										<img src="@/assets/images/main/main-article-3-2.png" alt="" />
									</picture>
								</div>
							</div>
						</swiper-slide>
						<swiper-slide>
							<div class="paper__item">
								<div class="image-area">
									<picture>
										<source
											srcset="@/assets/images/main/main-article-3-3.png"
											media="all and (min-width: 1281px)"
											type="image/png"
										/>
										<source
											srcset="@/assets/images/main/main-article-3-3-mo.png"
											media="all and (max-width: 1280px)"
											type="image/png"
										/>

										<img src="@/assets/images/main/main-article-3-3.png" alt="" />
									</picture>
								</div>
							</div>
						</swiper-slide>
						<swiper-slide>
							<div class="paper__item">
								<div class="image-area">
									<picture>
										<source
											srcset="@/assets/images/main/main-article-3-4.png"
											media="all and (min-width: 1281px)"
											type="image/png"
										/>
										<source
											srcset="@/assets/images/main/main-article-3-4-mo.png"
											media="all and (max-width: 1280px)"
											type="image/png"
										/>

										<img src="@/assets/images/main/main-article-3-4.png" alt="" />
									</picture>
								</div>
							</div>
						</swiper-slide>
						<swiper-slide>
							<div class="paper__item">
								<div class="image-area">
									<picture>
										<source
											srcset="@/assets/images/main/main-article-3-5.png"
											media="all and (min-width: 1281px)"
											type="image/png"
										/>
										<source
											srcset="@/assets/images/main/main-article-3-5-mo.png"
											media="all and (max-width: 1280px)"
											type="image/png"
										/>

										<img src="@/assets/images/main/main-article-3-5.png" alt="" />
									</picture>
								</div>
							</div>
						</swiper-slide>
						<swiper-slide>
							<div class="paper__item">
								<div class="image-area">
									<picture>
										<source
											srcset="@/assets/images/main/main-article-3-6.png"
											media="all and (min-width: 1281px)"
											type="image/png"
										/>
										<source
											srcset="@/assets/images/main/main-article-3-6-mo.png"
											media="all and (max-width: 1280px)"
											type="image/png"
										/>

										<img src="@/assets/images/main/main-article-3-6.png" alt="" />
									</picture>
								</div>
							</div>
						</swiper-slide>
					</Swiper>
					<div class="swiper-pagination"></div>
				</div>
				<PButton primary large class="alpha-btn" @click="go(RoutesConstants.SERVICE_INDEX)">AlphaKey 자세히 보기</PButton>
			</div>
		</article>
		<article class="main-article__4" id="article4">
			<div class="main-article__4_1">
				<div class="main__container">
					<div class="head-area">
						<img src="@/assets/images/main/icon-main-shield.svg" alt="" />
						<h3 class="title2">보안 사고를 예방할 수 있어요.</h3>
					</div>
					<div class="main-article__card">
						<div
							class="main-article__card__item animate-duration-600 animate-delay-200 animate-before-fadeinup"
							v-animateonscroll="{ enterClass: 'animate-fadeinup' }"
						>
							<div class="text-area">
								<h5 class="sub-title2">퇴사한 직원 접속</h5>
								<p class="body1">퇴사한 건설사 직원이 아파트 청약<br />고객 정보를 빼돌려 판매</p>
							</div>
							<img src="@/assets/images/main/main-article-4-1.png" alt="" />
						</div>
						<div
							class="main-article__card__item animate-duration-600 animate-delay-400 animate-before-fadeinup"
							v-animateonscroll="{ enterClass: 'animate-fadeinup' }"
						>
							<div class="text-area">
								<h5 class="sub-title2">고객 정보 유출</h5>
								<p class="body1">직원 161명이 업무 연관성이 없는<br />환자정보를 무단 열람</p>
							</div>
							<img src="@/assets/images/main/main-article-4-2.png" alt="" />
						</div>
						<div
							class="main-article__card__item animate-duration-600 animate-delay-600 animate-before-fadeinup"
							v-animateonscroll="{ enterClass: 'animate-fadeinup' }"
						>
							<div class="text-area">
								<h5 class="sub-title2">비밀번호 유출</h5>
								<p class="body1">
									주요 전상만 관리계정의 비밀번호를<br />123QWE처럼 쉬운 문자열로<br />
									설정해 해킹 사고 발생
								</p>
							</div>
							<img src="@/assets/images/main/main-article-4-3.png" alt="" />
						</div>
						<div
							class="main-article__card__item animate-duration-600 animate-delay-800 animate-before-fadeinup"
							v-animateonscroll="{ enterClass: 'animate-fadeinup' }"
						>
							<div class="text-area">
								<h5 class="sub-title2">미사용 계정 방치</h5>
								<p class="body1">미사용 관리자 계정을<br />장기간 방치해 고객 정보 유출</p>
							</div>
							<img src="@/assets/images/main/main-article-4-4.png" alt="" />
						</div>
					</div>
				</div>
				<div class="bg"></div>
			</div>
			<div class="main-article__4_2">
				<div class="main__container">
					<div class="main__sensing">
						<ul>
							<li>
								<div class="text-area">
									<h5 class="sub-title2">고객 정보 유출 사고 방지</h5>
									<p class="body1">
										인사 데이터와 연동해 퇴사자/접근 권한이 없는 계정은 <br class="only-pc" />
										시스템에 로그인할 수 없도록 자동 차단해요
									</p>
								</div>
								<img src="@/assets/images/main/main-article-sensing.svg" alt="" />
							</li>
							<li>
								<div class="text-area">
									<h5 class="sub-title2">해킹, 악성코드 등으로 인한 피해 예방</h5>
									<p class="body1">
										2차 인증에 양자내성암호(PQC)를 적용해<br class="only-pc" />
										계정 및 데이터 정보를 철저히 보호해요
									</p>
								</div>
								<img src="@/assets/images/main/main-article-security.svg" alt="" />
							</li>
						</ul>
					</div>
				</div>
			</div>
		</article>
		<article class="main-article__5" id="article5">
			<div class="main__container">
				<div class="head-area">
					<img src="@/assets/images/main/icon-main-light.svg" alt="" />
					<h3 class="title2">이런 기업에 AlphaKey를 추천해요.</h3>
				</div>
				<div class="main-article__square">
					<div
						class="main-article__square__item animate-duration-800 animate-delay-200 animate-before-fadeinup"
						v-animateonscroll="{ enterClass: 'animate-fadeinup' }"
					>
						<h6 class="body1">01</h6>
						<h4 class="sub-title2">
							클라우드 기반(SaaS) <br class="only-pc" />
							업무<br class="only-mobile" />
							솔루션/앱을 5개 이상 <br class="only-pc" />
							이용 중인 기업
						</h4>
						<img src="@/assets/images/icon/graphic/image-graphic-company.svg" alt="" />
					</div>
					<div
						class="main-article__square__item animate-duration-800 animate-delay-400 animate-before-fadeinup"
						v-animateonscroll="{ enterClass: 'animate-fadeinup' }"
					>
						<h6 class="body1">02</h6>
						<h4 class="sub-title2">
							업무 특성상 직원의 입/퇴사가 빈번해<br class="only-mobile" />
							계정 관리에 어려움이<br class="only-pc" />
							있는 기업
						</h4>
						<img src="@/assets/images/icon/graphic/image-graphic-briefcase.svg" alt="" />
					</div>
					<div
						class="main-article__square__item animate-duration-800 animate-delay-600 animate-before-fadeinup"
						v-animateonscroll="{ enterClass: 'animate-fadeinup' }"
					>
						<h6 class="body1">03</h6>
						<h4 class="sub-title2">
							사용 중인 구축형 로그인 <br class="only-pc" />
							보안 솔루션의<br class="only-mobile" />
							운영 비용이 부담스러운 기업
						</h4>
						<img src="@/assets/images/icon/graphic/image-graphic-complete_member.svg" alt="" />
					</div>
					<div
						class="main-article__square__item animate-duration-800 animate-delay-800 animate-before-fadeinup"
						v-animateonscroll="{ enterClass: 'animate-fadeinup' }"
					>
						<h6 class="body1">04</h6>
						<h4 class="sub-title2">
							임직원의 앱/솔루션<br class="only-pc" />
							이용 현황을<br class="only-mobile" />
							감사리포트로<br class="only-pc" />
							제출해야 하는 기업
						</h4>
						<img src="@/assets/images/icon/graphic/image-graphic-complete-document.svg" alt="" />
					</div>
				</div>
			</div>
		</article>
		<article class="main-article__6" id="article6">
			<div class="main__container">
				<div class="main-banner">
					<h5 class="sub-title2">
						AlphaKey가 궁금하다면<br class="only-mobile" />
						직접 경험해 보세요
					</h5>
					<p class="body2">지금 무료 체험을 신청할 수 있어요</p>
					<PButton class="w200" outlined large @click="go(RoutesConstants.TRIAL_INDEX)">무료 체험 신청</PButton>
					<PButton class="w200" primary large @click="go(RoutesConstants.INQUIRY_WRITE)">AlphaKey 간편상담</PButton>
				</div>
			</div>
		</article>
	</div>
</template>

<script lang="ts" setup>
import { ref, onMounted, onUnmounted } from 'vue'
import TabView from 'primevue/tabview'
import TabPanel from 'primevue/tabpanel'

import type { Swiper as SwiperInstance } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/vue'
import { Pagination, Navigation, Mousewheel, Autoplay, Thumbs, FreeMode, Virtual, EffectCreative } from 'swiper/modules'
import 'swiper/css'
import 'swiper/css/navigation'
import 'swiper/css/pagination'

import PButton from '~~/home/components/PButton.vue'
import HomeService from '~~/home/services/content/HomeService'
import type { BannerType } from '~~/home/types/content/homeTypes'
import RoutesConstants from '~~/home/types/route'
import Banner from '~~/home/layouts/common/Banner.vue'
import type { HomePageType } from '../types/home'
import HomeBannerService from '../services/home'

const { width } = useWindowSize()
const { scrollToSection: scrollAction } = useTools()
const { $config, $router } = useNuxtApp()
const go = (key: string) => {
	$router.push({ name: key })
}
const isMobile = computed(() => width.value < 720)

const showSwiper = ref(false)
const onVideoEnded = () => {
	showSwiper.value = true
	mainSwiper.value?.autoplay.start()
	document.querySelector('.main-article__1 .image')?.classList.add('active')
	document.querySelector('.main-article__1 .text-area h1')?.classList.add('animate-fadeinup')
	document.querySelector('.main-article__1 .text-area h1 + div')?.classList.add('animate-fadeinup')
}
const linkNavActive = ref(1)

const sections = [
	{ id: 'article2', navIndex: 1 },
	{ id: 'article4', navIndex: 2 },
	{ id: 'article5', navIndex: 3 }
]

const handleScroll = () => {
	const scrollPosition = window.scrollY

	for (const section of sections) {
		const element = document.getElementById(section.id)
		if (element) {
			const rect = element.getBoundingClientRect()
			const paddingTop =
				parseInt(window.getComputedStyle(element).paddingTop, 10) ||
				parseInt(window.getComputedStyle(element.children[0]).paddingTop, 10)
			if (scrollPosition >= rect.top + window.scrollY - paddingTop) {
				linkNavActive.value = section.navIndex
				continue
			}
		}
	}
}

// 스크롤 이벤트 최적화를 위한 쓰로틀 함수
const throttle = <T extends (...args: any[]) => void>(func: T, limit = 0) => {
	let inThrottle = false
	return (...args: Parameters<T>) => {
		if (!inThrottle) {
			func(...args)
			inThrottle = true
			setTimeout(() => (inThrottle = false), limit)
		}
	}
}

// 쓰로틀링이 적용된 스크롤 핸들러
const throttledScrollHandler = throttle(handleScroll, 100)

const scrollToSection = (sectionId: string) => {
	const element = document.getElementById(sectionId)
	if (element) {
		const rect = element.getBoundingClientRect()
		const paddingTop =
			parseInt(window.getComputedStyle(element).paddingTop, 10) ||
			parseInt(window.getComputedStyle(element.children[0]).paddingTop, 10)
		window.scrollTo({ top: rect.top + window.scrollY - (isMobile.value ? paddingTop - 5 : paddingTop - 18), behavior: 'smooth' })
	}
}

onMounted(() => {
	window.addEventListener('scroll', handleScroll)
})

onUnmounted(() => {
	window.removeEventListener('scroll', handleScroll)
})

const modules = [Navigation, Pagination, Autoplay]
const paginationOptions = {
	el: '.swiper-main-pagination',
	clickable: true
}

const navigationOptions = {
	nextEl: '.swiper-main-button-next',
	prevEl: '.swiper-main-button-prev'
}
const modules2 = [Pagination, Mousewheel, Autoplay, Virtual, EffectCreative]

const modules3 = [Thumbs, FreeMode]
const thumbsSwiper = ref(null)
const setThumbsSwiper = (Swiper: any) => {
	thumbsSwiper.value = Swiper
}
const mainSwiper = ref<SwiperInstance | null>(null)
const setMainSwiper = (Swiper: any) => {
	mainSwiper.value = Swiper
	mainSwiper.value?.autoplay.stop()
}

// 일시정지
const isPaused = ref<boolean>(false)
const pause = () => {
	if (!mainSwiper.value) {
		return
	}

	if (isPaused.value) {
		mainSwiper.value.autoplay.start()
		isPaused.value = false
	} else {
		mainSwiper.value.autoplay.stop()
		isPaused.value = true
	}
}

// const { data: bannerData, refetch } = await useAsyncData('home-banner', () => homeBannerService.getServiceData())
</script>

<style lang="scss" scoped>
.alpha-btn {
	width: 220px;
	font-weight: normal;
}
/*
.tabview-custom {
	.tabHeaderSwiper {
		max-width: 1184px;
	}
}

.tabview-custom__header {
	width: 288px;
}

.main-article__4 {
	.body1 {
		font-size: 16px;
	}
}
 */
</style>
